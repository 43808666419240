<template>
    <div class="flex flex-col md:flex-row w-screen min-h-screen bg-white dark:bg-gray-800">
        <div class="relative basis-[24vh] h-[24vh] md:absolute md:h-screen md:w-screen">
            <img src="/img/zuerioberland_gruningen_huhn.webp"  class="w-full h-full object-cover" />
        </div>
        <div class="flex z-20 flex-col gap-4 min-w-96 basis-[76vh] bg-white dark:bg-gray-800 px-8 md:pt-24 shrink-0 md:basis-1/4 transition-all duration-300 delay-100"
            v-bind:class="{ 
                '-translate-y-[24vh] md:translate-y-0 md:-translate-x-full' : !state.active,
        }">
            <LazyAccountLogin @registerButtonClicked="state.current = 2" @resetPasswordClicked="state.current = 3" v-if="state.current == 1" @loggedIn="state.active = false" />
            <LazyAccountRegister v-if="state.current == 2" @goBack="state.current = 1" />
            <LazyAccountResetPassword v-if="state.current == 3" @goBack="state.current = 1" />
        </div>
    </div>
</template>
<script setup>
useAuthRedirect()

definePageMeta({
    fullwidth: true,
    transparent: true,
    layout: 'server-rendered'
})
usePageTitle('Login')
const state = reactive({
    current: 1,
    active: false,
})

onMounted(function(){
    setTimeout(function() { state.active = true }, 275)
})
onBeforeUnmount(() => state.active = false )
const showHideLoginForm = () => {
    state.login = !state.login
}
</script>